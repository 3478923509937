import * as Redux from 'redux';
import { IAuthTicket } from '@logicmanager/common';

export const SET_AUTH_TOKEN: string = 'SET_AUTH_TOKEN';
export const LOGOUT_USER: string = 'LOGOUT_USER';
export const SET_INITIAL_AUTH_CHECK: string = 'SET_INITIAL_AUTH_CHECK';

export interface ISetAuth extends Redux.Action {
  readonly auth: IAuthTicket;
}

/** Store auth token from server */
export function setAuth(auth: IAuthTicket): ISetAuth {
  return { type: SET_AUTH_TOKEN, auth };
}

export function logoutUser(): Redux.Action {
  return { type: LOGOUT_USER };
}

export function setInitialAuthCheck(): Redux.Action {
  return { type: SET_INITIAL_AUTH_CHECK };
}
