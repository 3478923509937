import * as React from 'react';
import { GlobalErrorPageComponent } from '@logicmanager/common';
import { captureException } from '@sentry/react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './interfaces';

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {

    public static getDerivedStateFromError(error: Error): {hasError: boolean} {
        return { hasError: true };
    }

    public static getDerivedStateFromProps(nextProps: IErrorBoundaryProps,
                                           prevState: IErrorBoundaryState
                                          ): Partial<IErrorBoundaryState> {
        if (nextProps.location && nextProps.location !== prevState.location) {
            return { location: nextProps.location };
        }
        return null;
    }

    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            location: ''
        };
    }

    public componentDidCatch(error: Error, info: React.ErrorInfo): void {
        captureException(error);
    }

    public componentDidUpdate(prevProps: IErrorBoundaryProps, prevState: IErrorBoundaryState): void {
        /* if the user has tried to navigate away from the error page by means other than clicking
        the link to the homescreen provided in the error page - e.g clicking menu items, etc. */
        if (prevState.location !== this.state.location) {
            this.setState({ hasError: false });
        }
    }

    public render(): JSX.Element | React.ReactNode {
        return this.state.hasError
            ? <GlobalErrorPageComponent isFullScreenError={this.props.isFullScreenError}/>
            : this.props.children;
    }
}

// Component to be rendered at the crash-test route
export const CrashTest: () => void = (): void => { throw new Error('Crash Test Error'); };
