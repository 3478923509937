import * as React from 'react';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { ContentContainer } from '../containers/content';
import './root.less';
import { ErrorBoundary } from './error-boundary';

export function Root(): JSX.Element {
  const MainContentContainer: React.ComponentClass = withRouter(ContentContainer);
  return (
    <BrowserRouter>
      <div data-component="app">
        <ErrorBoundary isFullScreenError>
          <MainContentContainer/>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
  }
