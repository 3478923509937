import * as React from 'react';
import './ip-error.less';
import {
  DETECTIVE_TRIANGLE_IMAGE_PATH,
  IP_ERROR_TITLE,
  IP_ERROR_SUBTITLE_FIRST,
  IP_ERROR_SUBTITLE_SECOND,
  IP_ERROR_SUBTITLE_SECOND_BOLD,
  IP_ERROR_SUBTITLE_FIRST_BOLD,
  IP_ERROR_SUBTITLE_THIRD
} from './constants';

export const IpErrorPageComponent: React.FunctionComponent<{}> =
 React.memo(() => {
   return (
    <div className={'ip-error-page'}>
      <div className={'image-container'}>
        <div className={'background-image'}/>
        <img
          className={'img-detective'}
          src={DETECTIVE_TRIANGLE_IMAGE_PATH}
        />
      </div>
      <div className={'ip-error-message'}>
        <div className="title">{IP_ERROR_TITLE}</div>
        <div className="subtitle">
          {IP_ERROR_SUBTITLE_FIRST}
          &nbsp;<b>{IP_ERROR_SUBTITLE_FIRST_BOLD}</b>&nbsp; 
          {IP_ERROR_SUBTITLE_SECOND}
          &nbsp;<b>{IP_ERROR_SUBTITLE_SECOND_BOLD}</b>&nbsp;
          {IP_ERROR_SUBTITLE_THIRD}
        </div>
      </div>
    </div>
  );
});
