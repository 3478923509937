import * as React from 'react';
import { IResultsFacadeProps, IResultsFacadeState } from './results-facade-interfaces';
import {
  PageNotFoundComponent,
  TestApi,
  ITask
} from '@logicmanager/common';
import { Loader, Container } from 'semantic-ui-react';
import { IAppProps } from '@logicmanager/tasks';

const TasksApp: React.LazyExoticComponent<React.ComponentType<IAppProps>>
                 = React.lazy(() => import('@logicmanager/tasks'));
/**
 * Component that produces the Monitoring Results UI using TasksApp, by looking up the Task
 * by Test Result Id. We then pass the task Id down to our tasks app.
 */
export class ResultsFacade extends React.Component<IResultsFacadeProps, IResultsFacadeState> {
  public state: IResultsFacadeState = {
    taskId: null,
    error: false
  };

  public componentDidMount(): void {
    TestApi.getTaskByResultId(this.props.match.params.resultId).request()
      .then((task: ITask) => this.setState({taskId: task.id}))
      .catch(() => {
        this.setState({error: true});
      });
  }

  public getResultModal(): JSX.Element {
    const tasksAppWithProps: JSX.Element = (
      <TasksApp
        {...this.props}
        taskId={this.state.taskId}
        fromPlan
      />
    );
    const tasksApp: JSX.Element = this.props.location.state
      ? tasksAppWithProps
      : (<Container className="task-standalone-container">{tasksAppWithProps}</Container>);
    // if the state prop is valid, the task will be displayed in a modal
    return (
      <React.Suspense fallback={<Loader active />}>
        {tasksApp}
      </React.Suspense>
    );
  }

  public render(): JSX.Element {
    if (this.state.error) {
      return <PageNotFoundComponent/>;
    }
    if (this.state.taskId !== null) {
      return (this.getResultModal());
    }
    // don't have the taskId yet, display a loader.
    return (
      <Loader active />
    );
  }
}
