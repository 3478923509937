import * as React from 'react';
import { IIncidentsFacadeProps, IIncidentsFacadeState } from './incidents-facade-interfaces';
import { IncidentApi, ITask } from '@logicmanager/common';
import { Loader, Container } from 'semantic-ui-react';
import { IAppProps } from '@logicmanager/tasks';
import { autobind } from 'core-decorators';

const TasksApp: React.LazyExoticComponent<React.ComponentType<IAppProps>>
                 = React.lazy(() => import('@logicmanager/tasks'));
/**
 * Component that produces the Incidents UI using TasksApp, by looking up the Incident Task
 * by Incident ID.
 */
export class IncidentsFacade extends React.Component<IIncidentsFacadeProps, IIncidentsFacadeState> {
  private mounted: boolean= false;

  public state: IIncidentsFacadeState = {
    taskId: null
  };

  public componentDidMount(): void {
    this.mounted = true;
    this.fetchTaskByIncident();
  }

  public async componentDidUpdate(prevProps: Readonly<IIncidentsFacadeProps>): Promise<void> {
    if (this.props.match.params.incidentId !== prevProps.match.params.incidentId) {
      this.setState({ taskId: null }, async () => {
        this.fetchTaskByIncident();
      });
    }
  }

  public componentWillUnmount(): void {
    this.mounted = false;
  }

  @autobind
  public async fetchTaskByIncident(): Promise<void> {
    IncidentApi.getTaskByIncidentId({incidentId: this.props.match.params.incidentId}).request()
      .then((task: ITask) => {
        if (this.mounted) {
          this.setState({taskId: task.id});
        }
      });
  }

  public render(): JSX.Element {
    if (this.state.taskId !== null) {
      return (
        <React.Suspense fallback={<Loader active />}>
          <Container className="task-standalone-container">
            <TasksApp
              {...this.props}
              taskId={this.state.taskId}
            />
          </Container>
        </React.Suspense>
      );
    }
    // don't have the taskId yet, display a loader.
    return (
      <Loader active />
    );
  }
}
