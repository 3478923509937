let BUILD;
if (process.env.NODE_ENV === 'development') {
  BUILD = {
    LIBRARY_NAME: 'web-app',
    BASE_URL: 'https://sandbox.luigi.logicmanager.local/rest/frontend',
    CLASSIC_URL: 'https://sandbox.logicmanager.local',
    GA_ID: 'UA-231479-5',
    HORIZON_SUBDOMAIN: 'luigi',
    MARKETING_URL: '/static/login_placeholder.html',
    RECAPTCHA_SITE_KEY: '',
    EXCLUSIONS: [],
    SENTRY_DSN: 'https://30ab01250ee949f5a7084b1b2eb180cf@sentry.io/1820690',
    SENTRY_PROJECT: 'horizon-development@' + process.env.npm_package_version,
    CHAT_SUPPORT_URL:
      'https://logicmanager1577199237.zendesk.com/hc/en-us/articles/360051549093-Leveraging-Chat-Support',
    CONTENT_PACKAGES_URL: 'https://logicmanager1577199237.zendesk.com/hc/en-us',
    SENTRY_SAMPLE_RATE: .5,
  };
} else {
  BUILD = {
    LIBRARY_NAME: 'web-app',
    BASE_URL: '/rest-api/rest/frontend',
    CLASSIC_URL: '',
    GA_ID: 'UA-231479-5',
    HORIZON_SUBDOMAIN: 'my',
    MARKETING_URL: 'https://www.logicmanager.com/welcome/',
    RECAPTCHA_SITE_KEY: '',
    EXCLUSIONS: [],
    SENTRY_DSN: 'https://454f6edb887445fb8ff2fc58167cad7d@sentry.io/1476790',
    SENTRY_PROJECT: 'horizon-production@' + process.env.npm_package_version,
    CHAT_SUPPORT_URL:
      'https://support.logicmanager.com/hc/en-us/articles/360046926293-Leveraging-Chat-Support',
    CONTENT_PACKAGES_URL:
      'https://support.logicmanager.com/hc/en-us/categories/360004227914',
    SENTRY_SAMPLE_RATE: .1,
  };
}

module.exports = BUILD;
