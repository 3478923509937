import * as React from 'react';
import { ICreateElementFacadeProps, ICreateElementFacadeState } from './create-element-facade-interfaces';
import { TaxonomyApi,
         IElementExternalAction,
         TAXONOMY_RESOURCE_API,
         PageNotFoundComponent } from '@logicmanager/common';
import { Loader, Container } from 'semantic-ui-react';
import { IAppProps } from '@logicmanager/tasks';

const TasksApp: React.LazyExoticComponent<React.ComponentType<IAppProps>>
                 = React.lazy(() => import('@logicmanager/tasks'));
/**
 * Component that produces the Create an Element UI using TasksApp, by looking up the Element
 * by ExternalElementAction UUID. We then set that as the Task ID to get the psuedo task.
 */
export class CreateElementFacade extends React.Component<ICreateElementFacadeProps, ICreateElementFacadeState> {
  public state: ICreateElementFacadeState = {
    taskId: null,
    error: false
  };

  public componentDidMount(): void {
    TaxonomyApi.getExternalActionByResourceId({resource: this.props.match.params.resource as TAXONOMY_RESOURCE_API,
                                               id: parseInt(this.props.match.params.resourceId, 10)}).request()
                          .then((action: IElementExternalAction) => this.setState({taskId: action.uuid}))
                          .catch(() => {
                            this.setState({error: true});
                          });
  }

  public render(): JSX.Element {
    if (this.state.error) {
      return <PageNotFoundComponent/>;
    }
    if (this.state.taskId !== null) {
      return (
        <React.Suspense fallback={<Loader active />}>
          <Container className="task-standalone-container">
            <TasksApp
              {...this.props}
              noComments
              taskId={this.state.taskId}
            />
          </Container>
        </React.Suspense>
      );
    }
    // don't have the taskId yet, display a loader.
    return (
      <Loader active />
    );
  }
}
