import * as React from 'react';
import { IIssuesFacadeProps, IIssuesFacadeState } from './issues-facade-interfaces';
import {
         PageNotFoundComponent,
         IssueApi,
         ITask
        } from '@logicmanager/common';
import { Loader, Container } from 'semantic-ui-react';
import { IAppProps } from '@logicmanager/tasks';

const TasksApp: React.LazyExoticComponent<React.ComponentType<IAppProps>>
                 = React.lazy(() => import('@logicmanager/tasks'));
/**
 * Component that produces the Issues UI using TasksApp, by looking up the Task
 * by Issue Id. We then pass the task Id down to our tasks app.
 */
export class IssuesFacade extends React.Component<IIssuesFacadeProps, IIssuesFacadeState> {
  public state: IIssuesFacadeState = {
    taskId: null,
    error: false
  };

  public componentDidMount(): void {
    IssueApi.getTaskByIssueId(this.props.match.params.issueId).request()
                          .then((task: ITask) => this.setState({taskId: task.id}))
                          .catch(() => {
                            this.setState({error: true});
                          });
  }

  public getIssueModal(): JSX.Element {
    const tasksAppWithProps: JSX.Element = (
      <TasksApp
        {...this.props}
        taskId={this.state.taskId}
      />
    );
    const tasksApp: JSX.Element = this.props.location.state
      ? tasksAppWithProps
      : (<Container className="task-standalone-container">{tasksAppWithProps}</Container>);
    // if the state prop is valid, the issue will be displayed in a modal
    return (
      <React.Suspense fallback={<Loader active />}>
        {tasksApp}
      </React.Suspense>
    );
  }

  public render(): JSX.Element {
    if (this.state.error) {
      return <PageNotFoundComponent/>;
    }
    if (this.state.taskId !== null) {
      return (this.getIssueModal());
    }
    // don't have the taskId yet, display a loader.
    return (
      <Loader active />
    );
  }
}
