import 'core-js';
import 'regenerator-runtime';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BaseApi } from '@logicmanager/common';
import { App } from './containers/app';
import * as BUILD from '../config/config';
import './index.app.less';


/* Base Url is environment dependent */
BaseApi.configure({
  baseUrl: BUILD.BASE_URL
});

ReactDOM.render(
  <App />,
  document.getElementById('app-root')
);
