import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IAuthTicket } from '@logicmanager/common';
import { IState } from '../../interfaces';
import { Content } from './content';

export interface IContentStateProps extends RouteComponentProps<{}> {
  readonly auth: IAuthTicket;
}

export interface IContentConnectProps extends IContentStateProps,
  ReactRedux.DispatchProp<Redux.Action> {}

export function mapStateToProps(state: IState, props: RouteComponentProps<{}>): IContentStateProps {
  return { auth: state.auth, ...props };
}

export const ContentContainer: React.ComponentClass<RouteComponentProps<{}>>
  = ReactRedux.connect(mapStateToProps)(Content);
