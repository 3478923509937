import { IAuthTicket } from '@logicmanager/common';
import * as Actions from '../actions';

const initial: IAuthTicket = {
  token: '' ,
  email: '',
  firstName: '',
  lastName: '',
  userNo: 0,
  domain: '',
  ranInitialAuthCheck: false
};

/** Authenticated user's auth token */
export function auth(
  state: IAuthTicket = initial,
  action: Actions.ISetAuth
): IAuthTicket {
  switch (action.type) {
    case Actions.SET_AUTH_TOKEN:
      return {
        ...action.auth,
        ranInitialAuthCheck: true
      };
    case Actions.LOGOUT_USER:
      return {
        ...initial,
        ranInitialAuthCheck: true
      };
    case Actions.SET_INITIAL_AUTH_CHECK:
      return {
        ...state,
        ranInitialAuthCheck: true
      };
    default:
      return state;
  }
}
