import { HTTP_METHOD, LM_STORAGE_KEY, IAuthTicket } from '@logicmanager/common';

export class ExternalAppUtils {
  public static generateForm(url: string, attributes: Map<string, string>): void {
    const loadUrl: URL = new URL(url);
    const form: HTMLFormElement = document.createElement('form');

    form.setAttribute('action', loadUrl.href);
    form.setAttribute('method', HTTP_METHOD.POST);

    attributes.forEach((value: string, key: string, map: Map<string, string>) => {
      const tokenInputAttributes: Map<string, string> = new Map().set('type', 'hidden').set('name', key);
      const inputElement: HTMLInputElement = this.createInputElement(tokenInputAttributes, value);
      form.appendChild(inputElement);
    });

    const newWindow: Window = window.open();
    // Explicitly use open and close for older browsers
    newWindow.document.open();
    newWindow.document.write(form.outerHTML);
    newWindow.document.getElementsByTagName('form')[0].submit();
    newWindow.document.close();
  }

  public static openReportPortal(): void {
    const authInfo: IAuthTicket = this.getAutoInfo();
    const attributes: Map<string, string> = new Map().set('ticket', authInfo.token);
    this.generateForm(authInfo.reportUrl, attributes);
  }

  private static getAutoInfo(): IAuthTicket {
    return JSON.parse(localStorage.getItem(LM_STORAGE_KEY));
  }

  private static createInputElement(attributes: Map<string, string>, value: string): HTMLInputElement {
    const e: HTMLInputElement = document.createElement('input');

    attributes.forEach((v: string, k: string) => e.setAttribute(k, v));
    e.value = value;

    return e;
  }

}
